.paar__upload__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px dotted #848282;
    padding: .4rem .6rem;
    border-radius: 4px;
    font-size: 16px;
    height: 50px;
    cursor: pointer;
    color: #575555;
}

.paar__upload__input label {
    display: flex;
    cursor: pointer;
}

.file {
   display: none;
}