.login{
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    overflow-y: hidden;
    /* background: #eef3fb; */
}

.left {
    background-image: linear-gradient(to bottom, rgba(5, 5, 5, 0.52), rgba(39, 36, 38, 0.73)), url('../../assets/images/kinsooBackground.jpg');
    background-position: fixed center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #033237;
}

.logo{
    height: 80px;
    width: auto;
}