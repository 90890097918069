.mainNav{
    margin: 0, 10px !important;
}

.sideNav Button{
    color: white;
    padding-left: 20px;
    text-decoration: none;
}
.css-tsyg7e {
    border-radius: 50%;
}

.img{
    object-fit: contain;
}
.sideNav .css-7h6tl0-MuiListItem-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -webkit-text-decoration: none;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 24px;
    margin-top: 3px;
    margin-bottom: 2.4px;
    width: 100%;
    border-radius: 0.375rem;
    cursor: pointer;
    background-color: white !important;
    -webkit-transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.sideNav .css-3d7jb6 span {
    color: black !important;
    font-weight: 400;
    font-size: 0.875rem;
    opacity: 1;
    -webkit-transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.sideNav .css-1r5a91r span {
    color: black !important;
    font-weight: 400;
    font-size: 0.875rem;
    opacity: 1;
    -webkit-transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.sideNav .css-3d7jb6::before {
    content: "A";
    color: black !important;
    font-weight: 400;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -0.9375rem;
    opacity: 1;
    border-radius: 50%;
    font-size: 0.875rem;
}