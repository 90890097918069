canvas.react-pdf_Page_canvas {
    width: 400px   !important;
    height: 500px !important;
}

div.react-pdf_Page{
    width: 400px !important;
}
element.style {
    display: block;
    user-select: none;
    width: 442px !important;
    height: 430px !important;
}