.picker .css-eimhud-MuiSvgIcon-root {
    fill: black !important;
}

.gender .css-eimhud-MuiSvgIcon-root {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: black;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: inherit;
    font-size: inherit !important;
  }
  .userinfo > Button {
      color: black !important;
      padding-left: 20px;
  }
  .userinfo .css-dhopo2 {
      display: block;
      position: relative;
      overflow-x: hidden;
      min-height: 264px;
      color: black !important;
  }
select { 
     background-color: transparent;
  } 