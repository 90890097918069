
.css-mabkav-MuiTypography-root, .css-1fbohew-MuiTypography-root, .css-1xqkl4q-MuiTypography-root, .css-1neevca-MuiIcon-root    {
    color: white !important;
    font-size: larger;

}

.css-mabkav-MuiTypography-root, .css-1fbohew-MuiTypography-root, .css-1xqkl4q-MuiTypography-root{
    font-size: 2.3rem;
}
/* .css-ev0ro7-MuiButtonBase-root-MuiIconButton-root .material-icons-round {
    font-size: 2.5rem!important;
} */
.css-1y7q7ma span {

font-size: 1rem !important;

}
