.stepper .css-zyfrhq-MuiStepLabel-label {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 0.01071em;
  display: block;
  -webkit-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-top: 0.5rem !important;
  font-weight: 400;
  font-size: 0.75rem;
  color: black !important;
  text-transform: uppercase;
}

.stepper .css-eimhud-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: inherit;
  font-size: inherit !important;
}

.stepper .css-zyfrhq-MuiStepLabel-label.Mui-completed {
  font-weight: 400 !important;
  color: green !important;
}

.stepper .css-zyfrhq-MuiStepLabel-label.Mui-active {
  font-weight: 400 !important;
  color: black !important;
}

.css-ughkmj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: .9px !important;
  padding-right: 3px;
}

.css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  background-color: rgba(0, 0, 0, 0);
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  color: #495057;
  padding: 0.8rem !important;
  background-color: transparent;
}


.css-1xr7jc4-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  background-color: rgba(0, 0, 0, 0);
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 100%;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  padding-top: 16.5px;
  padding-right: 14px;
  padding-bottom: 16.5px;
  padding-left: 14px;
  padding-right: 0;
  color: #495057;
  padding: 0.8rem !important;
  background-color: transparent;
}

.css-1xr7jc4-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  background-color: rgba(0, 0, 0, 0);
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0%;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  padding-top: 16.5px;
  padding-right: 14px;
  padding-bottom: 16.5px;
  padding-left: 14px;
  padding-right: 0;
  color: grey !important;
  padding: 0.8rem !important;
  background-color: transparent;
}

.css-1l0271r-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
  display: none !important;
}

.css-1grlh6e-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 2px !important;
  padding-right: 9px;
}