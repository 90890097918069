.bank .css-eimhud-MuiSvgIcon-root {
    fill: black !important;
    font-size: inherit;
    font-size: inherit !important;
  }

  .bank .css-1xwgyfb-MuiSvgIcon-root {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: black !important;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.25rem;
    font-size: 1.25rem !important;
  }